import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Seo from "../seo";
import { Link } from "gatsby";

import Layout from "./Layout";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import VideoPlay from "./VideoPlay";
import IndivScheduleCard from "./IndivScheduleCard";
import Paperform from "../contactus/Paperform";

import { Icon } from "../../globals/icons";
import { StyledSubTitle1, StyledSubTitle2, StyledDesc1, StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import { hasuraConScheduleState } from "./HasuraCon22State.js";
import { COLORS } from "../../globals/designSystem";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import hasuraConRecordingBg from "./images/hasuracon-recording-bg.png";
import registerBg from "./images/register-bg.png";

const StyledSectionWrapperBg = styled(StyledSectionWrapper)`
  background-color: ${COLORS.black};
  background-image: url(${hasuraConRecordingBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const StyledHasuraConIndivPageWrapper = styled.div`
  .back {
    padding-bottom: 48px;
    display: inline-flex;
    .leftIcon {
      margin-right: 8px;
    }
  }
  .registerWrapper {
    background-image: url(${registerBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 625px;
    margin-bottom: -350px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06), 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 16px 24px -1px rgba(0, 0, 0, 0.12), 0px 20px 38px -2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .registerFormBg {
      background-color: rgba(0, 0, 0, .6);
      padding: 32px;
      border-radius: 8px;
      width: 100%;
      max-width: 720px;
    }
  }
  .hasuraConSpecsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    padding-top: 200px;
    .hasuraConSpeakersWrapper {
      border: 1px solid ${COLORS.grey_20};
      border-radius: 8px;
      padding: 32px;
      align-self: start;
      .speakersListWrapper {
        padding-top: 24px;
        .speakersList {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          .speakerImg {
            background: radial-gradient(
              154.08% 149.63% at 17.79% 40.05%,
              #5175f4 0%,
              #31e4d6 61%,
              #ffab2d 100%
            );
            border-radius: 50%;
            margin-right: 16px;
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            overflow: hidden;
            img {
              width: 60px;
              min-width: 60px;
            }
          }
        }
      }
    }
  }
  .otherRecordingsWrapper {
    padding: 52px 0;
    .otherRecordingsListWrapper {
      padding-top: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }
  }
  ${mq.between("md", "lg")} {
    .back {
      padding-bottom: 32px;
    }
    .registerWrapper {
      min-height: auto;
    }
    .hasuraConSpecsWrapper {
      padding-top: 150px;
      grid-gap: 60px;
      .hasuraConSpeakersWrapper {
        padding: 24px;
      }
    }
    .regiFormPadd {
      padding-top: 240px;
    }
    .otherRecordingsWrapper {
      .otherRecordingsListWrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  ${mq.below.md} {
    .back {
      padding-bottom: 24px;
    }
    .registerWrapper {
      min-height: auto;
      .registerFormBg {
        padding: 16px;
      }
    }
    .hasuraConSpecsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      padding-top: 30px;
      .hasuraConSpeakersWrapper {
        padding: 20px;
        .speakersListWrapper {
          .speakersList {
            .speakerImg {
              width: 40px;
              height: 40px;
              min-width: 40px;
              min-height: 40px;
              img {
                width: 40px;
                min-width: 40px;
              }
            }
            .mobileHide {
              display: none;
            }
          }
        }
      }
    }
    .regiFormPadd {
      padding-top: 280px;
    }
    .otherRecordingsWrapper {
      .otherRecordingsListWrapper {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const HasuraCon22IndivPage = props => {
  const currentSlug = props.pageContext.slug;
  const currentPageFilter = hasuraConScheduleState.filter(b => b.id === currentSlug);
  const currentPage = currentPageFilter[0];
  if (!currentPageFilter) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  const ogImage = { ogImage: currentPage.metaTags.metaImg };

  const [isAliId, setIsAliId] = useState(false);
  const [isLocalHasuraConIndivPage, setIsLocalHasuraConIndivPage] = useState(false);
  const [skipGating, setSkipGating] = useState(false);

  const onSubmitCB = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("hasuraConIndivPageConsent", "true");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchAliId = searchParams.get("aliId");
    const searchPromoCode = searchParams.get("promo_code");

    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }

    if (!!searchPromoCode && searchPromoCode === "LEARNWITHHASURA") {
      setSkipGating(true);
    }

    if (typeof window !== "undefined") {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const hasuraConIndivPageConsent = window.localStorage.getItem("hasuraConIndivPageConsent");
        if (hasuraConIndivPageConsent) {
          setIsLocalHasuraConIndivPage(true);
        }
      }
    }
  }, [props.location.search]);

  return (
    <Layout location={props.location}>
      <Seo
        title={currentPage.metaTags.title}
        description={currentPage.metaTags.description}
        meta={ogImage}
        canonicalLink={currentPage.metaTags.canonicalUrl}
      />
      <div id="indiv-recording">
      <StyledSectionWrapperBg>
        <StyledContainerWrapper>
          <StyledHasuraConIndivPageWrapper>
            <div className="back">
              <Link to="/events/hasura-con-2022/">
                <StyledDesc1 variant="white" fontWeight="font_600" css={flexCenter}><Icon className="leftIcon" variant="arrow_left" color="white" size="sm" />Back to Homepage</StyledDesc1>
              </Link>
            </div>
            {
              skipGating ? (
                <VideoPlay currentPage={currentPage}/>
              ) : (
                <Fragment>
                  {
                    isLocalHasuraConIndivPage ? (
                      <VideoPlay currentPage={currentPage}/>
                    ) : (
                      <div className="registerWrapper">
                        <div className="registerFormBg">
                          <Paperform
                            formId="zevrlxlg"
                            onSubmitCB={onSubmitCB}
                          />
                        </div>
                      </div>
                    )
                  }
                </Fragment>
              )
            }
          </StyledHasuraConIndivPageWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapperBg>
      <StyledSectionWrapper>
        <StyledContainerWrapper>
          <StyledHasuraConIndivPageWrapper>
            <div className={"hasuraConSpecsWrapper" + ((isLocalHasuraConIndivPage) ? "" : " regiFormPadd")}>
              <div>
                <StyledSubTitle1 paddingBottom="pb24">{currentPage.title}</StyledSubTitle1>
                <StyledDesc2>{currentPage.desc}</StyledDesc2>
              </div>
              <div className="hasuraConSpeakersWrapper">
                <StyledDesc2 fontWeight="font_600">Speaker{currentPage.authorDetails.length > 1 ? "s" : ""}</StyledDesc2>
                <div className="speakersListWrapper">
                  {
                    currentPage.authorDetails.map((speaker, index) => (
                      <div key={index} className="speakersList">
                        <div className="speakerImg">
                          <img src={speaker.authorImg} alt={speaker.name} />
                        </div>
                        <div>
                          <StyledDesc3>{speaker.name}<br/><span className="mobileHide">{speaker.designation}</span></StyledDesc3>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="otherRecordingsWrapper">
              {
                currentPage.confType !== "panel_discussion" ? <StyledSubTitle2>Other recordings</StyledSubTitle2> : null
              }
              <div className="otherRecordingsListWrapper">
                {
                  hasuraConScheduleState.map((currentConf, index) => (
                    <Fragment key={index}>
                      {
                        currentConf.confType === currentPage.confType ? (
                          <Fragment>
                            {
                              currentConf.title !== currentPage.title ? (
                                <IndivScheduleCard currentConf={currentConf} location={props.location} />
                              ) : null
                            }
                          </Fragment>
                        ) : null
                      }
                    </Fragment>
                  ))
                }
              </div>
            </div>
            <div className="back">
              <a href="/events/hasura-con-2022/#talks">
                <StyledDesc1 variant="grey_100" fontWeight="font_600" css={flexCenter}><Icon className="leftIcon" variant="arrow_left" color="grey_100" size="sm" />View all recordings</StyledDesc1>
              </a>
            </div>
          </StyledHasuraConIndivPageWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
      </div>
    </Layout>
  );
};

export default HasuraCon22IndivPage;
