import React from "react";
import styled from "styled-components";

import StyledVideoWrapper from "../shared/StyledVideoWrapper";

import { mq } from "../../globals/utils";

const StyledVideoWrapperHasuraCon = styled(StyledVideoWrapper)`
  margin-bottom: -350px;
  .videoAspectRatio {
    iframe {
      border-radius: 8px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06), 0px 10px 20px rgba(0, 0, 0, 0.12), 0px 16px 24px -1px rgba(0, 0, 0, 0.12), 0px 20px 38px -2px rgba(0, 0, 0, 0.06);
    }
  }
  ${mq.between("md", "lg")} {
    margin-bottom: -250px;
  }
  ${mq.below.md} {
    margin-bottom: -100px;
  }
`;

const VideoPlay = ({ currentPage }) => (
  <StyledVideoWrapperHasuraCon>
    <div className="videoAspectRatio">
      <iframe
        loading="lazy"
        title={currentPage.title}
        src={currentPage.videoLink}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  </StyledVideoWrapperHasuraCon>
);

export default VideoPlay;
